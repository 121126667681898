import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`What is Accessibility?`}</h2>
    <p>{`Web accessibility ensures that people with disabilities can perceive, understand, navigate, interact with, and contribute to the applications you create. This means that pages are `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG20"
      }}><strong parentName="a">{`Perceivable, Operable, Understandable and Robust`}</strong></a>{`. This includes providing keyboard interaction alternatives for all mouse-based actions, properly identifying all form fields and buttons, providing text based alternatives for all images, videos, icons and SVGs, as well as building components that properly convey their identity, operation model, and state to assistive technologies.`}</p>
    <p>{`The Design System enables accessible development by providing a set of semantically correct components, each with appropriate `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria"
      }}><strong parentName="a">{`ARIA`}</strong></a>{` markup so they can be identified correctly to users of assistive technologies.`}</p>
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/foundation/accessibility/Hierarcy.svg",
          "alt": "accessibility"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<h2 parentName="div">{`Structure and hierarchy`}</h2>{`
    `}<br parentName="div"></br>{`
    `}<p parentName="div">{`
      Consistent, clear hierarchy helps people who navigate the page using links or headers. Use headings and titles to
      outline the page so people can see the structure and how sections relate to each other.
    `}</p>{`
  `}</div>
    </div>
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/foundation/accessibility/Typo1.svg",
          "alt": "accessibility"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<h2 parentName="div">{`Typography`}</h2>{`
    `}<br parentName="div"></br>{`
    `}<p parentName="div">{`
      Clear typography helps ensure the content is readable and everyone can follow the flow of the text. This means
      making sure the text is big enough to read and the spacing around the text makes it easy to distinguish what
      should be read.
    `}</p>{`
  `}</div>
    </div>
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/foundation/accessibility/Contrast.svg",
          "alt": "accessibility"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<h2 parentName="div">{`Colors`}</h2>{`
    `}<br parentName="div"></br>{`
    `}<p parentName="div">{`
      We comply with `}<a parentName="p" {...{
            "href": "http://www.w3.org/TR/WCAG/"
          }}>{`AA standard contrast ratios`}</a>{`. To do this, we choose
      primary, secondary and extended colors that support usability. This ensures sufficient color contrast between
      elements so that people with low vision can see and use our products.
    `}</p>{`
  `}</div>
    </div>
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/foundation/accessibility/Image1.svg",
          "alt": "accessibility"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<h2 parentName="div">{`Images and video`}</h2>{`
    `}<br parentName="div"></br>{`
    `}<p parentName="div">{`
      For important information, use text rather than images. Only use images that usefully add to the text. Describe
      non-text elements in the UI or with alternative text so screen readers can succinctly describe images and media.
      Avoid moving or flashing images if possible.
    `}</p>{`
  `}</div>
    </div>
    <br />
    <br />
    <h2>{`Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI"
        }}><strong parentName="a">{`W3C Web Accessibility Initiative`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://w3c.github.io/aria-practices"
        }}><strong parentName="a">{`WAI-ARIA Authoring Practices`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://webaim.org/"
        }}><strong parentName="a">{`WebAIM`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://a11yproject.com/"
        }}><strong parentName="a">{`The A11Y Project`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.aremycolorsaccessible.com/"
        }}><strong parentName="a">{`Are My Colors Accessible?`}</strong></a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      